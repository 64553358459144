.ceoWrap {
  margin-top: 80px;
  margin-bottom: 40px;
}

.ceoHeading {
  background: #3f3f3f;
  /* background: rgba(0, 201, 167, 0.1); */
  width: 180px;
  height: 45px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 6px solid #ce8508;
  border-left: 6px solid #ce8508;
  margin-bottom: -20px;
  position: relative;
  z-index: 1;
}

.ceoHeading h2 {
  font-family: Museo-700;
  font-size: 26px;
  text-transform: uppercase;
  color: #ffffff;
}

.ceoContent {
  width: 320px;
  margin: auto;
  box-shadow: 0 0 10px #909090;
  padding-top: 40px;
  padding-bottom: 24px;
  border-right: 3px solid #ce8508;
  border-left: 3px solid #ce8508;
}

.ceoImg {
  margin: auto;
  text-align: center;
}

.ceoImg img {
  /* width: 280px;
  height: 340px; */
  width: 250px;
  height: 375px;
  border: 0.5px solid #ffffff;
  box-shadow: 0 0 10px #909090;
}

.ceoDescription {
  width: 280px;
  margin: auto;
  margin-top: 25px;
}

.ceoDescription p {
  font-family: Museo-300;
  font-size: 16px;
  line-height: 22px;
  text-align: justify;
  margin-top: 10px;
}

.ceoDescription strong {
  font-family: Museo-700;
  font-size: 18px;
  line-height: 22px;
}

@media screen and (min-width: 768px) {
  .ceoWrap {
    margin-top: 100px;
    margin-bottom: 50px;
  }

  .ceoHeading {
    width: 200px;
    height: 55px;
    margin-bottom: -24px;
  }

  .ceoHeading h2 {
    font-size: 30px;
  }

  .ceoContent {
    width: 700px;
    margin: auto;
    padding-top: 40px;
    padding-bottom: 0px;
  }

  .ceoImg img {
    /* width: 250px;
    height: 320px; */
    width: 210px;
    height: 320px;
    float: left;
    margin: 20px;
  }

  .ceoDescription {
    width: 100%;
    padding: 20px;
    padding-top: 0px;
  }

  .ceoDescription p {
    font-size: 18px;
    line-height: 28px;
    margin-top: 10px;
  }

  .ceoDescription p:nth-child(1) {
    margin-top: 0px;
  }

  .ceoDescription strong {
    font-size: 20px;
  }
}

@media screen and (min-width: 1024px) {
  .ceoWrap {
    margin-top: 120px;
  }

  .ceoHeading {
    width: 260px;
    height: 58px;
  }

  .ceoHeading h2 {
    font-size: 34px;
  }

  .ceoContent {
    width: 1000px;
    border-right: 6px solid #ce8508;
    border-left: 6px solid #ce8508;
  }

  .ceoImg img {
    /* width: 250px;
    height: 320px; */
    width: 215px;
    height: 320px;
    margin: 30px;
  }

  .ceoDescription {
    padding: 30px;
    padding-top: 0px;
  }

  .ceoDescription p {
    font-size: 20px;
    line-height: 34px;
  }

  .ceoDescription strong {
    font-family: Museo-700;
    font-size: 22px;
  }
}
