.visionMission {
  background: url("./Vision.jpg") no-repeat;
  background-size: 100% 100%;
  background-attachment: fixed;
  color: #ffffff;
  position: relative;
  text-align: center;
  transform: skew(0deg, -5deg);
  margin-top: 30px;
  margin-bottom: 30px;
  overflow-x: hidden;
}

.visionMissionBackground {
  background: rgba(0, 0, 0, 0.7);
  padding-top: 60px;
  padding-bottom: 70px;
}

.visionWrap {
  width: 250px;
  margin: auto;
  transform: skew(0deg, 5deg);
  cursor: pointer;
}

.visionLogo {
  /* border: 4px solid #ce8508; */
  border: 4px solid #ce8508;
  outline: 2px solid #ffffff;
  width: 50px;
  height: 50px;
  transform: rotate(-45deg);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  background-color: rgb(255, 255, 255);
}

.visionWrap:hover .visionLogo {
  animation: vision 1s ease;
}
@keyframes vision {
  0% {
    transform: rotate(-45deg);
  }
  25% {
    transform: rotate(45deg);
  }
  50% {
    transform: rotate(-45deg);
  }
  75% {
    transform: rotate(45deg);
  }
  100% {
    transform: rotate(-45deg);
  }
}

.visionLogo i {
  font-size: 26px;
  color: #ce8508;
  transform: rotate(45deg);
}

.visionHeading {
  border: 2px solid #ffffff;
  border-bottom: none;
  background-color: #05145f;
  background-color: #ce8508;
  width: 250px;
  height: 88px;
  margin-top: -26px;
  padding-bottom: 8px;
  color: #ffffff;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  text-transform: uppercase;
}

.visionHeading h3 {
  font-family: Museo-700;
  font-size: 24px;
  letter-spacing: 1px;
}

.visionDescription {
  border: 3px solid #ce8508;
  border-top: none;
  background: #ffffff;
  width: 250px;
  color: #000000;
  padding: 20px 20px;
}

.visionDescription p {
  font-family: Museo-300;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.7px;
}

/* --------------------------------MISSION-------------------- */

.missionWrap {
  padding-top: 30px;
  width: 250px;
  cursor: pointer;
  transform: skew(0deg, 5deg);
  margin: auto;
}

.missionLogo {
  border: 4px solid #ce8508;
  outline: 2px solid #ffffff;
  width: 50px;
  height: 50px;
  transform: rotate(45deg);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  background-color: rgb(255, 255, 255);
}

.missionWrap:hover .missionLogo {
  animation: mission 1s ease;
}
@keyframes mission {
  0% {
    transform: rotate(-45deg);
  }
  25% {
    transform: rotate(45deg);
  }
  50% {
    transform: rotate(-45deg);
  }
  75% {
    transform: rotate(45deg);
  }
  100% {
    transform: rotate(-45deg);
  }
}
.missionLogo i {
  font-size: 28px;
  color: #ce8508;
  transform: rotate(45deg);
}

.missionHeading {
  border: 2px solid #ffffff;
  border-bottom: none;
  background-color: #ce8508;
  width: 250px;
  height: 88px;
  margin-top: -26px;
  padding-bottom: 8px;
  color: #ffffff;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  text-transform: uppercase;
}

.missionHeading h3 {
  font-family: Museo-700;
  font-size: 24px;
  letter-spacing: 1px;
}

.missionDescription {
  border: 3px solid #ce8508;
  /* border: 3px solid #05145f; */
  border-top: none;
  background: #ffffff;
  width: 250px;
  color: #000000;
  padding: 10px 36px 16px;
  padding-right: 26px;
}

.missionDescription li {
  margin-top: 10px;
  text-align: left;
}

.missionDescription span {
  font-family: Museo-300;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.7px;
  position: relative;
  left: 12px;
}

@media screen and (min-width: 768px) {
  .visionMission {
    background-size: 100% 100%;
    background-attachment: fixed;
    margin-top: 60px;
  }

  .visionMissionBackground {
    display: flex;
    justify-content: center;
  }

  .visionWrap {
    width: 270px;
  }

  .visionHeading {
    width: 270px;
    height: 100px;
    padding-bottom: 14px;
  }

  .visionHeading h3 {
    font-size: 28px;
    letter-spacing: 1.7px;
  }

  .visionDescription {
    width: 270px;
    padding: 20px 20px;
  }

  .visionDescription p {
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0.8px;
  }

  /* --------------------------------MISSION-------------------- */

  .missionWrap {
    width: 270px;
  }

  .missionHeading {
    width: 270px;
    height: 100px;
    padding-bottom: 14px;
  }

  .missionHeading h3 {
    font-size: 28px;
    letter-spacing: 1.7px;
  }

  .missionDescription {
    width: 270px;
    padding: 10px 36px 16px;
    padding-right: 26px;
  }

  .missionDescription span {
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0.8px;
  }
}

@media screen and (min-width: 1024px) {
  .visionMission {
    /* background: url("./V4.jpg") no-repeat; */
    background-size: 100% 100%;
    margin-top: 60px;
  }
  .visionWrap {
    margin-right: -30px;
  }
  /* --------------------------------MISSION-------------------- */

  .missionWrap {
    width: 600px;
    margin-top: -20px;
    padding-bottom: 20px;
  }

  .missionHeading {
    width: 600px;
    height: 100px;
    padding-bottom: 14px;
  }

  .missionHeading h3 {
    font-size: 28px;
    letter-spacing: 1.7px;
  }

  .missionDescription {
    width: 600px;
    padding: 10px 36px 16px;
    padding-right: 26px;
  }

  .missionDescription span {
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0.8px;
  }
}

@media screen and (min-width: 1639px) {
  .visionMission {
    margin-top: 80px;
  }
}
