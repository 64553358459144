.projectsContainer {
  margin-top: 30px;
  text-align: center;
}

.projectsContainer h2 {
  font-family: Museo-700;
  color: #05145f;
  color: #ffffff;
  font-size: 16px;
  background: #3f3f3f;
  display: inline-block;
  padding: 10px;
  border-right: 6px solid #ce8508;
  border-left: 6px solid #ce8508;
  cursor: pointer;
  transition: all 0.3s ease-in;
}

.projectsContainer h2:hover {
  transform: scale(1.2);
}

.projects {
  position: relative;
  width: 280px;
  margin: auto;
}

.projects img {
  margin-top: 28px;
  width: 280px;
  height: 330px;
  box-shadow: 5px 5px 5px #585858;
}

.projectsNames h4 {
  font-family: Museo-300;
  background: rgba(0, 0, 0, 0.6);
  padding: 5px 8px;
  font-size: 14px;
  display: inline;
  position: absolute;
  left: 6px;
  top: 34px;
  color: #ffffff;
}

/* -----------------Box Shadow Buildings------------------------ */
.residentialWrap {
  width: 300px;
  margin: 30px auto;
  /* box-shadow: 0 0 8px #585858; */
  padding-top: 20px;
  padding-bottom: 20px;
  background: #e6e3e3;
  border: 2px solid #c8c4c4;
}

.residentialWrap h2 {
  background: none;
  border: none;
  color: #05145f;
}

/* -----------------Flat Buildings------------------------ */
.flat img {
  width: 280px;
  height: 200px;
}

.flat2 .projects:nth-child(13) img {
  height: 160px;
}
.flat2 .projects:nth-child(14) img {
  height: 160px;
}

/* -----------------Hoarding Flat----------------------- */

.HoardingFlat {
  position: relative;
  text-align: center;
  width: 280px;
  margin: auto;
}

.HoardingFlat img {
  margin-top: 28px;
  width: 280px;
  height: 160px;
  box-shadow: 5px 5px 5px #585858;
}

.HoardingFlatNames h4 {
  font-family: Museo-300;
  background: rgba(0, 0, 0, 0.6);
  padding: 5px 8px;
  font-size: 14px;
  display: inline;
  position: absolute;
  left: 6px;
  top: 34px;
  color: #ffffff;
}

/*-----------residentialTowerContinue-------------------- */

.residentialTowerContinue {
  margin-top: -26px;
}

/* -----------MEP-------- */
#MEP {
  margin-bottom: 35px;
}

/* ---------------------------------------Tab Version------------------------------------ */

@media screen and (min-width: 768px) {
  .projectsContainer h2 {
    font-size: 26px;
  }

  .projectsWrap {
    margin: 0;
    display: grid;
    grid-template-columns: 300px 300px;
    justify-content: space-around;
    margin-left: 40px;
    margin-right: 60px;
  }

  .projectsWrap img {
    margin-top: 40px;
    width: 300px;
    height: 330px;
  }

  .projectsNames h4 {
    font-size: 15px;
    top: 46px;
  }

  /* -----------------Box Shadow Buildings------------------------ */

  .residentialWrap {
    width: 90%;
    margin: 30px auto;
    padding-top: 20px;
    padding-bottom: 20px;
    background: #e6e3e3;
  }
  .residentialWrap .projectsWrap {
    margin-left: 5px;
    margin-right: 24px;
  }

  /* -----------------Flat Buildings------------------------ */
  .flat img {
    width: 300px;
    height: 200px;
  }

  .flat2 .projects:nth-child(13) img {
    height: 180px;
  }
  .flat2 .projects:nth-child(14) img {
    height: 180px;
  }

  /* -----------------Hoarding Flat----------------------- */

  .HoardingFlatWrap {
    margin: 0;
    display: grid;
    grid-template-columns: 300px 300px;
    justify-content: space-around;
    margin-left: 40px;
    margin-right: 60px;
  }

  .HoardingFlatWrap img {
    margin-top: 40px;
    width: 300px;
    height: 180px;
  }

  .HoardingFlatNames h4 {
    font-size: 15px;
    top: 46px;
  }
}

/* -------------------------------Desktop Version---------------------------------- */

@media screen and (min-width: 1101px) {
  .projectsContainer {
    margin-top: 40px;
  }

  .projectsContainer h2 {
    font-size: 26px;
    margin-bottom: 0px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .projectsWrap {
    display: grid;
    grid-template-columns: 320px 320px 320px;
    justify-content: space-around;
    margin-left: 20px;
    margin-right: 50px;
  }
  .projects {
    position: relative;
  }
  .projectsWrap img {
    margin-top: 0;
  }
  .projectsPhotos {
    position: relative;
    width: 320px;
    height: 340px;
    margin-top: 40px;
    background-size: cover;
    overflow: hidden;
    cursor: pointer;
  }

  .projectsWrap img {
    width: 100%;
    height: 100%;
    overflow: hidden;
    transition: 0.5s;
  }

  .projectsNames h4 {
    background: none;
    top: 0;
    left: 0;
    padding: 0;
  }

  /* -----------------Box Shadow Buildings------------------------ */

  .residentialWrap {
    width: 95%;
    margin: 60px auto;
    padding-top: 30px;
    padding-bottom: 30px;
    background: #e6e3e3;
  }
  .residentialWrap .projectsWrap {
    margin-left: -10px;
    margin-right: 30px;
  }

  /* -----------------Flat Buildings------------------------ */
  .flat .projectsPhotos {
    width: 320px;
    height: 220px;
  }
  /* .flat img {
    width: 320px;
    height: 220px;
  }
*/
  .flat2 .projects:nth-child(13) img {
    height: 220px;
  }
  .flat2 .projects:nth-child(14) img {
    height: 220px;
  }

  /* -----------------Hoarding Flat----------------------- */

  .HoardingFlatWrap {
    display: grid;
    grid-template-columns: 540px 540px;
    justify-content: space-around;
    margin-left: 50px;
    margin-right: 20px;
  }

  .HoardingFlat {
    position: relative;
    margin: 0;
  }

  .HoardingFlat img {
    margin-top: 0;
    margin-left: 0;
  }

  .HoardingFlatPhotos {
    position: relative;
    width: 520px;
    height: 240px;
    margin-top: 40px;
    background-size: cover;
    overflow: hidden;
    cursor: pointer;
  }

  .HoardingFlat img {
    width: 100%;
    height: 100%;
    overflow: hidden;
    transition: 0.5s;
  }

  .HoardingFlatNames h4 {
    background: none;
    top: 0;
    left: 0;
    padding: 0;
  }

  /*-----------residentialTowerContinue-------------------- */

  .residentialTowerContinue {
    margin-top: 10px;
  }

  /*-----------hovering image-------------------- */

  .projectsNames {
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    transition: 0.5s;
    overflow: hidden;
  }
  .projectsNames::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
    transform: translateX(-100%) scale(1) skew(-45deg);
    transition: 0.5s;
  }
  .projectsNamesContent {
    position: absolute;
    width: 200px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
  .projectsNamesContent h4 {
    font-size: 16px;
    width: 100%;
    transform: translateY(-100%) scale(0);
    transition: 0.5s;
  }
  .projectsPhotos:hover .projectsNamesContent h4 {
    transform: translateY(0) scale(1);
  }
  .projectsPhotos:hover img {
    transform: scale(1.4);
  }
  .projectsPhotos:hover .projectsNames::before {
    opacity: 1;
    background: rgba(255, 0, 0, 0.8);
    background: #020d41e3;
    transform: translateX(0%) scale(1) skew(-45deg);
  }

  /* -----------------Hoarding Flat hovering image------------------------ */

  .HoardingFlatNames {
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    transition: 0.5s;
    overflow: hidden;
  }
  .HoardingFlatNames::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
    transform: translateX(-100%) scale(1) skew(-45deg);
    transition: 0.5s;
  }
  .HoardingFlatNamesContent {
    position: absolute;
    width: 200px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
  .HoardingFlatNamesContent h4 {
    font-size: 16px;
    width: 100%;
    transform: translateY(-100%) scale(0);
    transition: 0.5s;
  }
  .HoardingFlatPhotos:hover .HoardingFlatNamesContent h4 {
    transform: translateY(0) scale(1);
  }
  .HoardingFlatPhotos:hover img {
    transform: scale(1.4);
  }
  .HoardingFlatPhotos:hover .HoardingFlatNames::before {
    opacity: 1;
    background: rgba(255, 0, 0, 0.8);
    background: #020d41e3;
    transform: translateX(0%) scale(1) skew(-45deg);
  }
}
