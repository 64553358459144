.ourClients {
  padding-top: 25px;
  padding-bottom: 25px;
}

.ourClients h2 {
  text-align: center;
  color: rgb(6, 6, 151);
  margin-bottom: 34px;
  font-size: 30px;
}

.ourClientsWrap {
  margin-left: 25px;
  margin-right: 25px;
}

/* .ourClientsLogo {
  width: 180px;
  height: 120px;
  border: 3px solid #ffffff;
} */

.ourClientsWrap img {
  width: 140px;
  /* height: 200px; */
  height: auto;
  object-fit: cover;
  margin: auto;
  border: 2px solid #0000007c;
}

.slidesClient .slick-prev,
.slidesClient .slick-next {
  color: rgb(6, 6, 151);
  top: 50%;
  z-index: 1;
}

.slidesClient .slick-prev {
  left: 5%;
}

.slidesClient .slick-next {
  right: 5%;
}

.slidesClient .slick-prev::before,
.slidesClient .slick-next::before {
  font-size: 20px;
  opacity: 1;
  color: rgba(0, 0, 0, 0.486);
}

.ourClientsViewMore {
  background: #0f4e97;
  width: 110px;
  height: 35px;
  /* font-size: 14px; */
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  border-radius: 10px;
  margin-top: 18px;
}

.ourClientsViewMore1 {
  color: #ffffff;
  font-size: 14px;
}

/*---------------TAB VERSION---------------*/
@media screen and (min-width: 768px) {
  .ourClients {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .ourClients h2 {
    margin-bottom: 38px;
    font-size: 32px;
  }

  .ourClientsWrap {
    margin-left: 50px;
    margin-right: 50px;
  }

  .ourClientsWrap img {
    width: 140px;
  }

  .slidesClient .slick-prev {
    left: -2.8%;
  }

  .slidesClient .slick-next {
    right: -1%;
  }

  .slidesClient .slick-prev::before,
  .slidesClient .slick-next::before {
    font-size: 30px;
  }

  .ourClientsViewMore {
    margin: 0;
    margin-top: 30px;
    margin-bottom: 0px;
    margin-left: 75%;
  }
}

/*---------------DESKTOP VERSION---------------*/

@media screen and (min-width: 1024px) {
  .ourClients {
    padding-top: 40px;
  }

  .ourClientsWrap {
    margin-left: 100px;
    margin-right: 100px;
  }

  .ourClientsWrap img {
    width: 140px;
  }

  .slidesClient .slick-prev::before,
  .slidesClient .slick-next::before {
    font-size: 35px;
  }

  .ourClientsViewMore {
    width: 120px;
    height: 40px;
    margin-top: 30px;
    margin-left: 78%;
  }

  .ourClientsViewMore1 {
    font-size: 15px;
  }
  /* [data-aos="rotate-c"] {
    transform: rotate(-270deg);
    transition-property: transform;
  } */
}
