@media screen and (max-width: 1023px) {
  .servicesDescWrap {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .servicesDescContainer {
    width: 100%;
    margin-top: 40px;
    overflow-x: hidden;
  }

  .servicesDescImg {
    width: 300px;
    height: 140px;
    margin: auto;
    display: flex;
    justify-content: center;
  }

  .servicesDescImg img {
    width: 120px;
    height: 120px;
    border: 2px solid rgb(0, 172, 143, 0.5);
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    position: relative;
    z-index: 1;
  }

  .servicesDescContent {
    width: 280px;
    margin: auto;
    margin-top: 10px;
    border: 2px solid rgb(0, 172, 143, 0.5);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    margin-top: -80px;
  }

  .servicesDescContent h2 {
    font-family: Museo-700;
    text-align: center;
    font-size: 24px;
    color: rgb(0, 172, 143);
    background-color: rgb(0, 201, 167, 0.1);
    padding: 4px;
    padding-top: 80px;
    letter-spacing: 1px;
    border-bottom: 2px solid rgb(0, 172, 143, 0.5);
  }

  .servicesDescContent p {
    font-family: Museo-300;
    text-align: justify;
    margin-top: 10px;
    line-height: 22px;
    letter-spacing: 0.1px;
    padding: 12px;
  }
}

@media screen and (min-width: 768px) {
  .servicesDescWrap {
    display: grid;
    grid-template-columns: 50% 50%;
    justify-content: center;
  }
}

@media screen and (min-width: 1024px) {
  .servicesDescWrap {
    display: block;
    margin-bottom: 60px;
  }

  .servicesDescContainer {
    display: flex;
    margin: 0 80px;
    padding-top: 80px;
    overflow-x: hidden;
  }

  .servicesDescImg {
    width: 260px;
    height: 220px;
    border: 2px solid rgb(0, 172, 143, 0.5);
    border-left: 2px solid rgb(0, 172, 143);
    border-left-width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .servicesDescImg img {
    width: 220px;
    height: 200px;
  }

  .servicesDescContent {
    margin-left: 20px;
    margin-right: 20px;
    border-bottom: 2px solid rgb(0, 172, 143, 0.5);
  }

  .servicesDescContent h2 {
    font-family: Museo-700;
    font-size: 30px;
    letter-spacing: 0.6px;
    color: rgb(0, 121, 101);
    /* color: #000000; */
    /* display: inline-block; */
    background-color: rgb(0, 172, 143, 0.2);
    padding: 6px 22px;
    text-transform: uppercase;
    border-left: 2px solid rgb(0, 172, 143);
    border-left-width: 14px;
    /* padding-top: 10px; */
  }

  .servicesDescContent p {
    font-family: Museo-300;
    font-size: 20px;
    line-height: 26px;
    letter-spacing: 0.5px;
    padding-top: 14px;
  }

  .servicesDescContainer:nth-child(even) {
    flex-direction: row-reverse;
  }
  .servicesDescContainer:nth-child(even) .servicesDescImg {
    border-left-width: 2px;
    border-right: 2px solid rgb(0, 172, 143);
    border-right-width: 20px;
  }
}
