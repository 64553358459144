  .slide img {
  width: 100%;
  /* height: 530px; */
  height: 100%;
  /* height: auto; */
  /* object-fit: contain; */
  margin: auto;
  margin-top: -0.7px;
}

.slides .slick-prev,
.slides .slick-next {
  top: 43%;
  z-index: 1;
}

.slides .slick-prev {
  left: 3%;
}

.slides .slick-next {
  right: 3%;
}

.slides .slick-prev::before,
.slides .slick-next::before {
  font-size: 20px;
  opacity: 0.7;
  color: rgb(255, 255, 255);
}

.slides .slick-dots li button:before {
  font-size: 8px;
  margin-top: -30px;
  color: #ffffff;
}
.slick-dots li.slick-active button:before {
  opacity: 1;
}

@media screen and (min-width: 768px) {
  /* .slide img {
    margin-top: 120px;
  } */

  .slide .slideDescription {
    background: rgba(0, 0, 0, 0.7);
    color: #ffffff;
    display: inline-block;
    /* position: relative;
    bottom: 78px;
    left: 15px; */
    position: absolute;
    bottom: 20px;
    margin-left: 12px;
    z-index: 1;
    padding: 10px;
  }

  .slide .slideDescription h3 {
    font-weight: 500;
    font-size: 17px;
  }

  .slide .slideDescription h4 {
    font-weight: 400;
    font-size: 12px;
    margin-top: 5px;
    color: rgba(255, 255, 255, 0.794);
  }

  .slides .slick-prev::before,
  .slides .slick-next::before {
    font-size: 30px;
  }

  .slides .slick-dots li button:before {
    font-size: 12px;
    margin-top: -36px;
  }
}

@media screen and (min-width: 1024px) {
  .slide .slideDescription {
    position: absolute;
    bottom: 26px;
    margin-left: 24px;
    z-index: 1;
    padding: 10px;
  }

  .slide .slideDescription h3 {
    font-weight: 500;
    font-size: 24px;
  }

  .slide .slideDescription h4 {
    font-weight: 400;
    font-size: 14px;
    margin-top: 8px;
    color: rgba(255, 255, 255, 0.794);
  }

  .slides .slick-prev::before,
  .slides .slick-next::before {
    font-size: 35px;
  }

  .slides .slick-dots li button:before {
    font-size: 14px;
    margin-top: -45px;
  }
}
