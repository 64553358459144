.slideDesktop {
  display: none;
}

@media screen and (min-width: 768px) {
  .slideDesktop {
    display: block;
  }
  .slideMobile {
    display: none;
  }
}
