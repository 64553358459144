.backgroundContactWrap {
  position: relative;
}

.backgroundContact {
  background: url("./Contact.jpg") no-repeat;
  background-size: cover;
  text-align: center;
  background-attachment: fixed;
  background-position: center;
}

.backgroundContactContainer {
  height: 480px;
  /* background-image: linear-gradient(150deg, #de2c26f5 50%, #243f88 50%); */
  background-image: linear-gradient(150deg, #00000052 50%, #0000008e 50%);
  background-color: transparent;
  mix-blend-mode: multiply;
}

.backgroundContactContent {
  color: #ffffff;
  width: 300px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin: auto;
  text-align: center;
}

.backgroundContactContent h1 {
  font-size: 32px;
  margin-top: -55px;
  margin-bottom: 30px;
  /* text-shadow: 1px 1px 2px #ffffff; */
}

.backgroundContactContent p {
  font-size: 18px;
  line-height: 22px;
  width: 220px;
  margin: auto;
  /* text-shadow: 1px 1px 3px #ffffff; */
}

@media screen and (min-width: 768px) {
  .backgroundContactContainer {
    height: 440px;
  }

  .backgroundContactContent {
    width: 480px;
    margin: auto;
    text-align: center;
  }

  .backgroundContactContent h1 {
    font-size: 42px;
    transform: translate(-35%, -20%);
  }

  .backgroundContactContent p {
    font-size: 22px;
    width: 300px;
    line-height: 32px;
    transform: translate(50%, 80%);
  }
}

@media screen and (min-width: 1024px) {
  .backgroundContactContainer {
    height: 520px;
  }

  .backgroundContactContent {
    width: 640px;
  }

  .backgroundContactContent h1 {
    font-size: 54px;
    transform: translate(-35%, 10%);
  }

  .backgroundContactContent p {
    font-size: 36px;
    width: 480px;
    line-height: 50px;
    transform: translate(36%, 32%);
  }
}
