.projectNav {
  background: rgba(225, 233, 176, 0.924);
  height: 45px;
  position: relative;
  z-index: 1;
  position: sticky;
  top: 0;
  display: flex;
}

.project-menu {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 100%;
}

.project-item {
  list-style: none;
  width: 100;
  height: 100%;
  display: flex;
  align-items: center;
}

.project-links {
  font-family: Museo-700;
  color: rgb(0, 0, 0);
  cursor: pointer;
  /* transition: 0.5s ease; */
}

.project-item:hover .project-links {
  color: #ffffff;
  background: #020d41;
  border-top: 1px solid #ffffff;
  padding: 10px;
  padding-top: 13px;
  padding-bottom: 12px;
}

.project-icon {
  display: none;
}

.project-item > .project-nav-active {
  color: #ffffff;
  border: none;
  background: #ce8508;
  border-top: 1px solid #ffffff;
  background: #020d41;
  padding: 10px;
  padding-top: 22px;
  padding-bottom: 24px;
}

/* -------------TAB VERSION---------- */

@media screen and (max-width: 1100px) {
  .projectNav {
    height: 90px;
    /* position: relative;
    z-index: 100;
    position: sticky;
    top: 0;
    display: flex; */
  }

  .project-menu {
    display: grid;
    grid-template-columns: 20% 20% 20% 20% 20%;
  }

  .project-item {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .project-links {
    font-size: 14px;
  }

  .project-item:hover .project-links {
    border-top: 1px solid #ffffff;
    padding: 10px;
    padding-top: 2px;
    padding-bottom: 2px;
  }

  .project-item > .project-nav-active {
    border-top: 1px solid #ffffff;
    padding: 10px;
    padding-top: 2px;
    padding-bottom: 2px;
  }
}

/* -------------MOBILE VERSION---------- */
@media screen and (max-width: 767px) {
  .projectNav {
    height: 55px;
    z-index: 99;
  }

  .project-menu {
    display: flex;
    flex-direction: column;
    width: 220px;
    position: absolute;
    top: -400px;
    right: 0;
    transition: all 0.5s ease;
    padding: 10px;
    height: 320px;
  }

  .project-menu.active {
    background: #00002af8;
    background: rgba(212, 223, 143, 0.959);
    top: 55px;
    /* right: 0; */
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .project-links {
    text-align: center;
    padding: 0;
    display: table;
    margin: 0;
    margin-top: 0px;
    font-size: 16px;
    width: 100%;
    color: #000000;
  }

  .project-item:hover .project-links {
    border-top: none;
    padding: 10px;
    padding-top: 2px;
    padding-bottom: 2px;
  }

  .project-item > .project-nav-active {
    border-top: none;
    padding: 10px;
    padding-top: 2px;
    padding-bottom: 2px;
  }
  /* 
  .project-links:hover {
    background-color: #1888ff;
    border-radius: 12px;
  } */

  .project-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    font-family: Museo-700;
    transform: translate(-6%, 30%);
    font-size: 16px;
    cursor: pointer;
    border: 1px solid black;
    padding: 6px;
  }
}
