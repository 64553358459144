.backgroundAboutWrap {
  position: relative;
}

.backgroundAbout {
  background: url("./About.jpg") no-repeat;
  background-size: cover;
  text-align: center;
  background-attachment: fixed;
  background-position: center;
}

.backgroundAboutContainer {
  height: 480px;
  background-image: linear-gradient(150deg, #de2c26f5 50%, #243f88 50%);
  background-color: transparent;
  mix-blend-mode: multiply;
}

.backgroundAboutContent {
  color: #ffffff;
  width: 300px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin: auto;
  text-align: center;
}

.backgroundAboutContent h1 {
  font-size: 34px;
  margin-bottom: 30px;
}

.backgroundAboutContent p {
  font-size: 18px;
  line-height: 28px;
  margin-left: -80px;
}

.backgroundAboutContent span {
  display: block;
  margin-top: 10px;
  margin-left: 180px;
}

@media screen and (min-width: 768px) {
  .backgroundAboutContainer {
    height: 440px;
  }

  .backgroundAboutContent {
    width: 480px;
    margin: auto;
    text-align: center;
  }

  .backgroundAboutContent h1 {
    font-size: 44px;
  }

  .backgroundAboutContent p {
    width: 350px;
    font-size: 24px;
    line-height: 28px;
    transform: translate(70%, 40%);
  }
}

@media screen and (min-width: 1024px) {
  .backgroundAboutContainer {
    height: 520px;
  }

  .backgroundAboutContent {
    width: 640px;
  }

  .backgroundAboutContent h1 {
    font-size: 54px;
    margin-bottom: 0px;
  }

  .backgroundAboutContent p {
    width: 500px;
    font-size: 36px;
    line-height: 50px;
    transform: translate(75%, 50%);
  }
  .backgroundAboutWrap {
    width: 100%;
  }
}
