.sectorWrap {
  padding-top: 26px;
  padding-bottom: 20px;
}

.sectorHeading {
  margin-bottom: 26px;
  color: #020d41;
  text-align: center;
}

.sectorHeading h2 {
  font-size: 30px;
  background: linear-gradient(175deg, #de2d26 50%, #243f88 50%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.sectorType {
  background: #020d41;
  width: 200px;
  height: 40px;
  border-radius: 10px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
}

.sectorType h3 {
  font-size: 18px;
  font-weight: 500;
}

.logoBox {
  position: relative;
  width: 170px;
  height: 110px;
  margin: auto;
  transition: 0.5s ease;
}
.logoBox:hover {
  transform: scale(1.1);
}

.logoWrap {
  width: 170px;
  height: 110px;
  border: 2px solid #0000007c;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 28px;
  cursor: pointer;
}

.logoWrap img {
  /* display: block; */
  width: 150px;
  height: auto;
}

.logoText {
  display: none;
}

.logoOverlay {
  position: absolute;
  opacity: 0;
  transition: all 0.6s ease;
  width: 0%;
  height: 0;
  top: 0;
  left: 0;
  background-color: #008cba;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logoBox:hover .logoOverlay {
  height: 100%;
  width: 100%;
  opacity: 1;
  text-align: center;
}

.logoBox:hover .logoText {
  display: block;
  color: #ffffff;
  font-size: 12px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (min-width: 768px) {
  .sectorWrap {
    padding-top: 30px;
    padding-bottom: 0px;
  }

  .sectorHeading {
    margin-bottom: 36px;
  }

  .sectorHeading h2 {
    font-size: 38px;
  }

  .sectorType {
    width: 250px;
    height: 50px;
    margin-left: 60px;
  }

  .sectorType h3 {
    font-size: 22px;
  }

  .logoBox {
    position: relative;
    width: 190px;
    height: 125px;
    margin: 20px auto;
  }

  .logoWrap {
    width: 190px;
    height: 125px;
    margin-top: 0px;
  }

  .logoWrap img {
    width: 170px;
    height: auto;
  }

  .logoText {
    font-size: 14px;
  }

  .logoContainer {
    margin-left: 60px;
    margin-right: 60px;
    display: grid;
    grid-template-columns: 190px 190px 190px;
    justify-content: space-between;
    margin-top: -20px;
  }
}
@media screen and (min-width: 1024px) {
  .sectorWrap {
    padding-top: 50px;
    padding-bottom: 0px;
  }

  .sectorHeading {
    margin-bottom: 36px;
  }

  .sectorHeading h2 {
    font-size: 44px;
  }

  .sectorType {
    width: 390px;
    height: 50px;
    margin-left: 100px;
  }

  .sectorType h3 {
    font-size: 26px;
  }

  .logoBox {
    position: relative;
    width: 210px;
    height: 130px;
    margin: 30px auto;
  }

  .logoWrap {
    width: 210px;
    height: 130px;
  }

  .logoWrap img {
    width: 190px;
    height: auto;
  }

  .logoText {
    font-size: 14px;
  }

  .logoContainer {
    margin-left: 100px;
    margin-right: 100px;
    display: grid;
    grid-template-columns: 210px 210px 210px;
    justify-content: space-between;
  }
}

@media screen and (min-width: 1150px) {
  .logoContainer {
    margin-left: 100px;
    margin-right: 100px;
    display: grid;
    grid-template-columns: 210px 210px 210px 210px;
    justify-content: space-between;
  }
}

@media screen and (min-width: 1350px) {
  .logoContainer {
    margin-left: 100px;
    margin-right: 100px;
    display: grid;
    grid-template-columns: 210px 210px 210px 210px 210px;
    justify-content: space-between;
  }
}

/* ----------------PRIVATE SECTOR--------------------- */
.sectorHeadingPrivate {
  display: none;
}

.sectorTypePrivate {
  margin-top: -10px;
}

@media screen and (min-width: 768px) {
  .sectorTypePrivate {
    margin-top: -6px;
  }
}

@media screen and (min-width: 1024px) {
  .sectorTypePrivate {
    margin-top: -20px;
  }
}
