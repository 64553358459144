.map {
  width: 90%;
  margin: 60px auto;
}

.map iframe {
  width: 100%;
  height: 300px;
  border: 2px solid rgb(212, 212, 212);
}

@media screen and (min-width: 768px) {
  .map {
    width: 80%;
  }

  .map iframe {
    height: 450px;
    border: 2px solid rgb(212, 212, 212);
  }
}

@media screen and (min-width: 1024px) {
  .map {
    width: 75%;
  }

  .map iframe {
    height: 450px;
    border: 2px solid rgb(212, 212, 212);
  }
}
/* width="600"
        height="450"
        frameborder="0"
        style="border:0;"
        allowfullscreen=""
        aria-hidden="false"
        tabindex="0 */
