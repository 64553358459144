.footer {
  background: url("./FMob.PNG") no-repeat;
  background-size: 100% 100%;
  width: 100%;
  margin-top: 0px;
  color: #ffffffe3;
  padding-top: 20px;
  position: absolute;
}

.homeContact {
  margin: auto;
  text-align: center;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.homeContact i {
  padding-top: 4px;
  font-size: 18px;
}

.homeContact:nth-child(1) i {
  color: red;
  margin-top: 3px;
  margin-right: 15px;
}

.homeContact:nth-child(2) i {
  color: #1670d8;
  margin-right: -24px;
  padding-top: 14px;
}

.homeContact:nth-child(3) i {
  color: #02e0e0;
  margin-right: -14px;
}

.homeContact p {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 0.5px;
  width: 265.7px;
  font-size: 14px;
  font-weight: 400;
}

.homeContact:nth-child(3) p {
  letter-spacing: 0.8px;
}

/*----------CONTACT ICONS STYLE------------ */

.iconsFooter {
  text-align: center;
}

.btnFooter {
  display: inline-block;
  background: #f1f1f1;
  width: 40px;
  height: 40px;
  border-radius: 30%;
  margin: 10px 10px;
  color: #3498db;
  overflow: hidden;
  position: relative;
}

.btnFooter i {
  line-height: 40px;
  font-size: 16px;
  transition: 0.3s ease-in;
}
.btnFooter:nth-child(4) i {
  font-size: 20px;
}

.btnFooter:hover i {
  transform: scale(1.3);
  color: #f1f1f1;
}

.btnFooter::before {
  content: "";
  position: absolute;
  width: 60px;
  height: 55px;
  background: #3498db;
  transform: rotate(45deg);
  left: -110%;
  top: 90%;
}

.btnFooter:hover::before {
  animation: aaa 0.7s 1;
  top: -10%;
  left: -10%;
}

@keyframes aaa {
  0% {
    left: -110%;
    top: 90%;
  }
  50% {
    left: 10%;
    top: -30%;
  }
  100% {
    left: -10%;
    top: -10%;
  }
}
.iconsFooter {
  transform: scale(0.75);
  margin-top: 4px;
  margin-bottom: -12px;
}

/* ------------------------------------ */

.FooterBottomLine {
  width: 92%;
  height: 2px;
  margin: 12px auto 6px;
  background: #ffffff;
}

.copyright {
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.5px;
  text-align: center;
  width: 240px;
  margin: auto;
  font-weight: 500;
}

.copyright i {
  font-size: 12px;
}

.excellence {
  width: 120px;
  height: 120px;
  margin: 30px auto 20px;
}

.excellence img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
}

/* -------------Designed & Developed----------------- */
.designed {
  text-align: center;
  margin-top: 20px;
  background: #ffffff;
  padding: 4px;
}

.designed p {
  font-family: Museo-300;
  font-weight: bold;
  font-size: 14px;
  color: #000000;
  line-height: 20px;
}

.designed p span {
  font-family: Museo-300;
  font-size: 14px;
  color: #ce8508;
  letter-spacing: 0.5px;
}

@media screen and (min-width: 768px) {
  .footer {
    background: url("./F.PNG") no-repeat;
    background-size: 100% 100%;
    margin-top: 0px;
    padding-top: 60px;
  }

  .footerFlex {
    display: flex;
    justify-content: flex-start;
    padding-left: 80px;
  }

  .homeContact {
    margin-top: 16px;
  }

  .homeContact i {
    padding-top: 4px;
    font-size: 20px;
  }

  .homeContact:nth-child(1) i {
    margin-right: -6px;
  }

  .homeContact:nth-child(2) i {
    margin-right: -60px;
  }

  .homeContact:nth-child(3) i {
    margin-right: -40px;
  }

  .homeContact img {
    width: 22px;
    height: 18px;
    margin-top: 6px;
    margin-right: -24px;
  }

  .homeContact p {
    font-size: 15px;
    line-height: 28px;
    letter-spacing: 0.7px;
    width: 350px;
    margin-left: 0px;
  }

  .iconsFooter {
    transform: scale(0.85);
    margin-top: 14px;
    margin-bottom: 10px;
    margin-left: -40px;
  }

  .FooterBottomLine {
    display: none;
  }

  .copyright {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.5px;
    text-align: center;
    width: 100%;
    margin-top: 10px;
  }

  .copyright i {
    font-size: 14px;
  }

  .excellence {
    margin: 0;
    margin-top: 70px;
  }

  .footerWrap {
    margin-left: 10%;
  }

  /* -------------Designed & Developed----------------- */
  .designed {
    text-align: right;
    margin-top: 20px;
    padding: 4px;
  }

  .designed p {
    font-size: 15px;
    display: inline;
  }

  .designed p span {
    font-size: 15px;
  }

  .designed p:nth-child(2) {
    margin-left: 10px;
    border-left: 1px solid #000000;
    padding-left: 10px;
    margin-right: 8px;
  }
}

@media screen and (min-width: 1024px) {
  .footer {
    background: url("./F.PNG") no-repeat;
    background-size: 100% 100%;
    margin-top: 20px;
    padding-top: 40px;
  }

  .homeContact {
    margin-top: 20px;
  }

  .homeContact:nth-child(1) {
    margin-top: 40px;
  }

  .homeContact:nth-child(2) i {
    margin-right: -40px;
    padding-top: 18px;
  }
  .homeContact:nth-child(2) p {
    letter-spacing: 1.5px;
  }

  .iconsFooter {
    transform: scale(0.85);
    margin-top: 6px;
    margin-bottom: -15px;
    margin-left: -40px;
  }

  .copyright {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.5px;
    text-align: center;
    width: 100%;
    margin-top: 26px;
  }

  .copyright i {
    font-size: 14px;
  }

  /* ----------------Excellence -----------*/

  .excellence {
    width: 160px;
    height: 160px;
    margin-top: 100px;
    position: relative;
    left: 20px;
  }

  .excellence img {
    width: 160px;
    height: 160px;
  }

  .footerWrap {
    margin-left: 20%;
  }
}
