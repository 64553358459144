.WhoWeAre {
  margin-top: 50px;
  margin-bottom: 20px;
}

.WhoWeAreHeading {
  width: 185px;
  height: 50px;
  margin: auto;
}

.WhoWeAreHeading h2 {
  font-family: Museo-700;
  font-size: 26px;
  border-left: 3px solid #05145f;
  border-bottom: 3px solid #05145f;
  padding: 3px;
  padding-left: 8px;
  text-transform: uppercase;
  color: #05145f;
}

.WhoWeAreContent {
  width: 285px;
  text-align: justify;
  margin: 22px auto;
  font-size: 16px;
  line-height: 24px;
}

.WhoWeAreContent p {
  font-family: Museo-300;
  color: #000000ed;
  margin-top: 16px;
}

@media screen and (min-width: 768px) {
  .WhoWeAre {
    margin-top: 70px;
    margin-bottom: 20px;
  }

  .WhoWeAreHeading {
    width: 210px;
    height: 55px;
    margin-left: 80px;
  }

  .WhoWeAreHeading h2 {
    font-size: 30px;
  }

  .WhoWeAreContent {
    width: 82%;
    margin: 24px auto;
    margin-left: 80px;
    font-size: 18px;
    line-height: 30px;
  }
}

@media screen and (min-width: 1024px) {
  .WhoWeAre {
    margin-top: 100px;
    margin-bottom: 20px;
  }

  .WhoWeAreHeading {
    width: 240px;
    height: 60px;
    margin-left: 120px;
  }

  .WhoWeAreHeading h2 {
    font-size: 34px;
  }

  .WhoWeAreContent {
    width: 82%;
    margin: 26px;
    margin-left: 120px;
    font-size: 20px;
    line-height: 36px;
  }
}
