.backgroundClientsWrap {
  position: relative;
}

.backgroundClients {
  background: url("./Clients.jpg") no-repeat;
  background-size: cover;
  text-align: center;
  background-attachment: fixed;
  background-position: center;
}

.backgroundClientsContainer {
  height: 480px;
  background-image: linear-gradient(150deg, #de2d26 50%, #243f88 50%);
  background-color: transparent;
  mix-blend-mode: multiply;
}

.backgroundClientsContent {
  color: #ffffff;
  width: 300px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin: auto;
  text-align: center;
}

.backgroundClientsContent h1 {
  font-size: 30px;
  margin-bottom: 10px;
}

.backgroundClientsContent p {
  font-size: 14px;
  line-height: 22px;
  text-shadow: 0.5px 0.5px 0.5px #ffffff;
}

@media screen and (min-width: 768px) {
  .backgroundClientsContainer {
    height: 440px;
  }

  .backgroundClientsContent {
    width: 480px;
    margin: auto;
    text-align: center;
  }

  .backgroundClientsContent h1 {
    font-size: 42px;
    margin-bottom: 16px;
  }

  .backgroundClientsContent p {
    font-size: 20px;
    line-height: 28px;
  }
}

@media screen and (min-width: 1024px) {
  .backgroundClientsContainer {
    background-image: linear-gradient(150deg, #de2d26 50%, #243f88 50%);
    height: 520px;
  }

  .backgroundClientsContent {
    width: 640px;
  }

  .backgroundClientsContent h1 {
    font-size: 44px;
    margin-bottom: 16px;
  }

  .backgroundClientsContent p {
    font-size: 20px;
    line-height: 34px;
  }
}
