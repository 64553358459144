.ourServices {
  background: url("./Images/B4M.jpg");
  background-size: 100% 100%;
  background-attachment: fixed;
  z-index: -2;
}

.ourServicesBox {
  background: rgba(0, 0, 0, 0.8);
  z-index: -1;
}

.ourServices h2 {
  text-align: center;
  color: #ce8508;
  margin-bottom: 34px;
  font-size: 30px;
  padding-top: 25px;
}

.ourServicesWrap {
  text-align: center;
  margin: auto;
}

.ourServicesIcon {
  width: 160px;
  margin: auto;
  margin-bottom: 50px;
}

.ourServicesIcon img {
  width: 140px;
  height: 140px;
  border: 1px solid #000000;
  border-radius: 50%;
  padding: 20px;
  background: #ffffff;
}

.ourServicesIcon:hover img {
  /* transform: scale(1.2); */
  /* transition: 0.3s ease-in-out; */
  border: 4px solid #ce8508;
  animation: mymove 1s;
}

@keyframes mymove {
  50% {
    transform: scale(1.2);
    border: 6px solid #ce8508;
  }
  100% {
    transform: scale(1);
  }
}

.ourServicesIcon h4 {
  margin: auto;
  margin-top: 10px;
  font-size: 20px;
  color: #ffffff;
}

.ourServicesIcon:hover h4 {
  color: #ce8508;
}

/*---------------------TAB VERSION----------------------*/

@media screen and (min-width: 768px) {
  .ourServices {
    background: url("./Images/B4.jpg");
    background-size: 100% 100%;
    background-attachment: fixed;
    z-index: -2;
  }

  .ourServices h2 {
    margin-bottom: 50px;
    font-size: 34px;
    padding-top: 40px;
  }

  .ourServicesContainer {
    margin-left: 100px;
    margin-right: 100px;
  }

  .ourServicesWrap {
    display: grid;
    grid-template-columns: 50% 50%;
    justify-content: center;
  }

  .ourServicesIcon {
    margin-top: 0;
  }

  .ourServicesIcon img {
    width: 130px;
    height: 130px;
    padding: 20px;
  }

  .ourServicesIcon h4 {
    margin-top: 10px;
    font-size: 20px;
    width: 140px;
    color: white;
  }
}

/*---------------------TAB VERSION----------------------*/

@media screen and (min-width: 1024px) {
  .ourServicesContainer {
    margin-left: 80px;
    margin-right: 80px;
  }
  .ourServicesWrap {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    justify-content: center;
  }
}
