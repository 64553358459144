.borderBottom {
  border-bottom: 1px solid #00000093;
  /* margin-left: 15px;
  margin-right: 15px; */
  margin-top: -4px;
}

.homeAbout {
  display: block;
  margin: auto;
  margin-top: -4px;
  padding-top: 40px;
  padding-bottom: 30px;
  /* padding: 40px 40px; */
}

.homeAboutImg {
  display: none;
}

.homeAboutImgName h4 {
  background: rgba(0, 0, 0, 0.6);
  color: #ffffff;
  width: 200px;
  position: relative;
  z-index: 1;
  font-size: 14px;
  margin: auto;
  /* margin-left: 15px; */
  padding: 5px;
  margin-top: -40px;
  right: 40px;
}

.homeAboutDescription {
  text-align: center;
}

.homeAboutDescription h3 {
  font-family: Museo-700;
  font-size: 24px;
  margin-top: -14px;
  color: rgb(6, 6, 151);
}

.homeAboutDescription span {
  font-family: Museo-700;
  display: block;
  margin-top: 4px;
  animation: colorchange 2s infinite;
}
@keyframes colorchange {
  0% {
    color: rgb(6, 6, 151);
    text-shadow: 0px 0px 2px rgb(6, 6, 151);
  }

  /* 20% {
    color: #a54302;
  }

  40% {
    color: rgb(6, 6, 151);
  } */

  33% {
    color: red;
    text-shadow: 0px 0px 2px red;
  }

  66% {
    color: green;
    text-shadow: 0px 0px 2px green;
  }

  /* 100% {
    color: rgb(0, 107, 0);
  } */
}

.homeAboutDescription p {
  font-family: Museo-300;
  width: 282px;
  text-align: justify;
  margin: 18px auto;
  font-size: 16px;
  line-height: 24px;
  /* margin-top: 434px; */
  margin-top: 16px;
}

.homeAboutReadMore {
  background: #0f4e97;
  width: 160px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  border-radius: 10px;
}

.homeAboutReadMore1 {
  color: #ffffff;
  font-size: 16px;
}

/*-----------------TAB VERSION-------------*/
@media screen and (min-width: 768px) {
  .homeAboutBackground {
    background: url("./Images/B1.jpg") no-repeat;
    background-attachment: fixed;
    background-size: 100% 100%;
    margin-top: -30px;
    padding-bottom: 20px;
  }
  .borderBottom {
    border-bottom: none;
  }
  .homeAboutImg {
    display: block;
  }

  .homeAbout {
    display: flex;
    justify-content: space-around;
    flex-direction: row-reverse;
    width: 750px;
    padding-top: 70px;
    margin: auto;
  }

  .homeAboutImg img {
    width: 340px;
    height: 475px;
    margin-top: 0;
    display: block;
    margin: auto;
    box-shadow: 5px 5px 5px #585858;
  }

  .homeAboutImgName h4 {
    font-family: Museo-700;
    width: 250px;
    font-size: 16px;
    line-height: 22px;
    margin-left: 20px;
    padding: 8px;
    margin-top: -50px;
    right: 0px;
  }

  .homeAboutDescription {
    /* margin-top: 450px; */
    margin-top: 10px;
  }

  .homeAboutDescription span {
    display: inline-block;
  }

  .homeAboutDescription h3 {
    font-size: 24px;
  }

  .homeAboutDescription p {
    margin-top: 20px;
    margin-left: 6px;
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 26px;
    width: 340px;
  }

  .homeAboutReadMore {
    margin-left: 10px;
  }

  .homeAboutReadMore1 {
    font-size: 16px;
  }
}

/*-----------------DESKTOP VERSION-------------*/
@media screen and (min-width: 1024px) {
  .homeAboutBackground {
    margin-top: -10px;
  }

  .homeAbout {
    width: 1000px;
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .homeAboutImg img {
    width: 400px;
    height: 480px;
  }

  .homeAboutImgName h4 {
    width: 250px;
    font-size: 16px;
    line-height: 22px;
    margin-left: 20px;
    padding: 8px;
    margin-top: -55px;
  }

  .homeAboutDescription h3 {
    font-size: 30px;
    margin-left: -8px;
  }

  .homeAboutDescription span {
    display: inline-block;
  }

  .homeAboutDescription p {
    margin: 14px;
    margin-top: 18px;
    margin-left: 10px;
    font-size: 18px;
    line-height: 32px;
    width: 440px;
    color: rgba(0, 0, 0, 0.938);
  }

  .homeAboutReadMore {
    margin-left: 12px;
    margin-top: 22px;
  }

  .homeAboutReadMore1 {
    font-size: 16px;
  }
}
