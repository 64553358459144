* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
  background: "white";
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
}

/* .home,
.about-us,
.services,
.projects,
.clients,
.contact-us {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
} */

@font-face {
  font-family: Museo-700;
  src: url(./Components/Museo-700.otf);
}
@font-face {
  font-family: Museo-300;
  src: url(./Components/Museo-300.otf);
}
@font-face {
  font-family: Museo-100;
  src: url(./Components/Museo-100.otf);
}
