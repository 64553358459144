.slideMobile img {
  width: 100%;
  height: 430px;
  /* height: auto; */
  object-fit: cover;
  margin: auto;
  /* margin-top: 80px; */
}

.slideMobile .slideDescriptionMobile {
  background: rgba(0, 0, 0, 0.7);
  color: #ffffff;
  display: inline-block;
  position: absolute;
  bottom: 26px;
  margin-left: 12px;
  /* z-index: 2; */
  padding: 10px;
}

.slideMobile .slideDescriptionMobile h3 {
  font-weight: 500;
  font-size: 12px;
}

.slideMobile .slideDescriptionMobile h4 {
  font-weight: 400;
  font-size: 8px;
  margin-top: 5px;
  color: rgba(255, 255, 255, 0.794);
}

.slidesMobile .slick-prev,
.slidesMobile .slick-next {
  top: 50%;
  z-index: 1;
}

.slideMobile .slick-prev {
  left: 5%;
}

.slidesMobile .slick-next {
  right: 5%;
}

.slidesMobile .slick-prev::before,
.slidesMobile .slick-next::before {
  font-size: 22px;
  opacity: 0.7;
  color: rgb(255, 255, 255);
}

.slidesMobile .slick-dots li button:before {
  font-size: 10px;
  margin-top: -30px;
  color: #ffffff;
}
.slidesMobile .slick-dots li.slick-active button:before {
  opacity: 1;
}
.slidesMobile .slick-dots li {
  margin-left: -8px;
}

@media screen and (min-width: 768px) {
  .slidesMobile .slick-prev::before,
  .slidesMobile .slick-next::before {
    font-size: 30px;
  }

  .slidesMobile .slick-dots li button:before {
    font-size: 12px;
    margin-top: -35px;
  }

  .slidesMobile .slick-dots li {
    margin-left: 10px;
  }
}

@media screen and (min-width: 1024px) {
  .slidesMobile .slick-prev::before,
  .slidesMobile .slick-next::before {
    font-size: 35px;
  }

  .slidesMobile .slick-dots li button:before {
    font-size: 14px;
    margin-top: -100px;
  }
}
