.navbar {
  /* background: #343a40; */
  background: #020d41;
  height: 92px;
  display: flex;
  justify-content: space-between;
  font-weight: 700;
  position: relative;
  z-index: 100;
}

.navbar-logo {
  cursor: pointer;
  text-decoration: none;
  margin-left: 15px;
  margin-top: 10px;
}

.navbar-logo img {
  width: 320px;
  height: 70px;
}

.navbarBorder {
  background: #ffffff;
  width: 264px;
  height: 2px;
  position: relative;
  z-index: 1;
  margin-left: 50px;
  margin-top: -30px;
}

.navbar-logo h4 {
  color: #ffffff;
  font-family: "Changa", sans-serif;
  margin-left: 50px;
  margin-top: 0px;
  font-size: 18px;
  letter-spacing: 0.3px;
  text-shadow: 0px 0px 3px #ffffff;
  /* border-top: 1.5px solid white; */
  /* position: relative; */
  /* width: 304px; */
}

/* @media screen and (min-width: 1150px) {
  .navbar-logo img {
    width: 370px;
  }
  .navbar-logo h4 {
    margin-left: 55px;
    font-size: 22px;
    width: 306px;
  } */
/* } */

.nav-menu {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-right: 30px;
  text-shadow: 0px 0px 3px #ffffff;
}

.nav-item {
  margin-left: 0px;
  list-style: none;
}
@media screen and (min-width: 1227px) {
  .nav-item {
    margin-left: 20px;
  }
}

.nav-links {
  color: white;
  padding: 0.8rem 1rem;
  font-family: Museo-700;
  letter-spacing: 0.7px;
}

.nav-links:hover {
  background-color: #1888ff;
  border-radius: 12px;
  transition: all 0.4s ease-in;
}

.main-nav {
  /* color: #ffa200; */
  color: #ffa200;
  text-shadow: none;
  /* text-shadow: 0px 0px 2.3px #ffa200; */
}

.menu-icon {
  display: none;
}

/* -------------TAB VERSION---------- */

@media screen and (max-width: 1023px) {
  .navbar-logo img {
    width: 280px;
  }

  .navbarBorder {
    width: 230px;
    margin-left: 45px;
    margin-top: -30px;
  }

  .navbar-logo h4 {
    font-size: 16px;
    margin-left: 45px;
    letter-spacing: 0.2px;
  }

  .nav-menu {
    flex-wrap: wrap;
    margin-right: 10px;
    margin-left: 20px;
    margin-top: 16px;
  }

  .nav-links i {
    display: none;
  }
}

/* -------------MOBILE VERSION---------- */
@media screen and (max-width: 767px) {
  .navbar {
    height: 80px;
  }

  .navbar-logo {
    margin-left: 12px;
    cursor: pointer;
    text-decoration: none;
    justify-content: start;
  }

  .navbarBorder {
    width: 204px;
    margin-left: 38px;
    margin-top: -27.5px;
  }

  .navbar-logo h4 {
    font-size: 14px;
    margin-top: 1.5px;
    margin-left: 38px;
    width: 200px;
  }

  .navbar-logo img {
    width: 245px;
    height: 60px;
  }

  .fa-bars {
    color: rgba(255, 255, 255, 0.794);
    margin-right: -10px;
    margin-top: -4px;
    padding: 6px;
    border: 1px solid rgba(255, 255, 255, 0.507);
    border-radius: 0.25rem;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 65%;
    margin: 0;
    position: absolute;
    top: 80px;
    left: -100%;
    transition: all 0.5s ease;
    /* top: 20px; */
    padding-bottom: 16px;
  }

  .nav-menu.active {
    background: #00002af8;
    left: 0;
    /* top: 80px; */
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 1rem 3.8rem;
    display: table; /*!important*/
    margin: 0;
    margin-top: 18px;
    font-size: 1.1rem;
    width: 100%;
  }

  .nav-links:hover {
    background-color: #1888ff;
    border-radius: 12px;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: rgba(255, 255, 255, 0.794);
    font-size: 2rem;
  }
}
