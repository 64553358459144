.formContent {
  margin: 32px;
}

.formContent p {
  font-family: Museo-300;
  font-size: 18px;
  margin-bottom: 10px;
}

.formContent p span {
  color: red;
}

.formContent .formInput {
  font-family: Museo-300;
  font-size: 16px;
  letter-spacing: 0.5px;
  width: 100%;
  height: 40px;
  border: 1px solid rgb(0, 123, 255);
  border-radius: 5px;
  padding-left: 10px;
  color: #05145f;
}

.formContent .messageInput {
  height: 80px;
  padding-top: 10px;
}

.formContentbtn {
  text-align: center;
  margin-top: -6px;
}

.formContentbtn .btn {
  font-family: Museo-300;
  letter-spacing: 0.7px;
  font-size: 16px;
  border: none;
  border-radius: 6px;
  background: #0f4e97;
  width: 160px;
  height: 40px;
  color: #ffffff;
}

/* -------------------Tab Version----------------- */

@media screen and (min-width: 768px) {
  .formWrap {
    margin: 60px;
  }

  .formWrapFlex {
    display: grid;
    grid-template-columns: 50% 50%;
  }

  .formContent {
    margin: 20px 0px;
  }

  .formContent p {
    font-size: 20px;
    margin-bottom: 16px;
  }

  .formContent .formInput {
    font-size: 18px;
    width: 94%;
    height: 40px;
  }

  .formContent .messageInput {
    width: 96.5%;
    height: 100px;
  }

  .formContentbtn {
    margin-top: 0px;
  }

  .formContentbtn .btn {
    font-size: 18px;
    width: 180px;
    height: 45px;
  }
}

/* -------------------Desktop Version----------------- */

@media screen and (min-width: 1024px) {
  .formWrap {
    margin: 40px 140px;
  }

  .formWrapFlex {
    display: grid;
    grid-template-columns: 50% 50%;
  }

  .formContent {
    margin: 20px 0px;
  }

  .formContent p {
    font-size: 20px;
    margin-bottom: 16px;
  }

  .formContent .formInput {
    font-size: 18px;
    width: 100%;
    height: 40px;
  }

  .formContent .messageInput {
    width: 100%;
    height: 100px;
  }

  .formContent:nth-child(1) {
    margin-right: 20px;
  }

  .formContent:nth-child(2) {
    margin-left: 20px;
  }

  .formContentbtn {
    margin-top: 0px;
  }

  .formContentbtn .btn {
    font-size: 18px;
    width: 180px;
    height: 45px;
  }
}
