.wave {
  position: absolute;
  width: 100%;
  height: 25px;
  bottom: 0;
  left: 0;
  background: url(./waveMob.png);
  animation: animate 5s linear infinite;
}

.wave:before {
  content: "";
  width: 100%;
  height: 25px;
  background: url(./waveMob.png);
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.1;
  animation: animate-reverse 5s linear infinite;
}
.wave:after {
  content: "";
  width: 100%;
  height: 25px;
  background: url(./waveMob.png);
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.2;
  animation-delay: 2.5s;
  animation: animate 10s linear infinite;
}
@keyframes animate {
  0% {
    background-position: 0;
  }
  100% {
    background-position: 500px;
  }
}
@keyframes animate-reverse {
  0% {
    background-position: 500px;
  }
  100% {
    background-position: 0;
  }
}

@media screen and (min-width: 768px) {
  .wave {
    position: absolute;
    width: 100%;
    height: 40px;
    bottom: 0;
    left: 0;
    background: url(./waveTab.png);
    animation: animate 7s linear infinite;
  }

  .wave:before {
    content: "";
    width: 100%;
    height: 40px;
    background: url(./waveTab.png);
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.1;
    animation: animate-reverse 7s linear infinite;
  }
  .wave:after {
    content: "";
    width: 100%;
    height: 40px;
    background: url(./waveTab.png);
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.2;
    animation-delay: 3.5s;
    animation: animate 14s linear infinite;
  }

  @keyframes animate {
    0% {
      background-position: 0;
    }
    100% {
      background-position: 800px;
    }
  }
  @keyframes animate-reverse {
    0% {
      background-position: 800px;
    }
    100% {
      background-position: 0;
    }
  }
}

@media screen and (min-width: 1024px) {
  .wave {
    position: absolute;
    width: 100%;
    height: 50px;
    bottom: 0;
    left: 0;
    background: url(./wave.png);
    animation: animate 9s linear infinite;
  }

  .wave:before {
    content: "";
    width: 100%;
    height: 50px;
    background: url(./wave.png);
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.1;
    animation: animate-reverse 9s linear infinite;
  }
  .wave:after {
    content: "";
    width: 100%;
    height: 50px;
    background: url(./wave.png);
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.2;
    animation-delay: 4.5s;
    animation: animate 18s linear infinite;
  }

  @keyframes animate {
    0% {
      background-position: 0;
    }
    100% {
      background-position: 1360px;
    }
  }
  @keyframes animate-reverse {
    0% {
      background-position: 1360px;
    }
    100% {
      background-position: 0;
    }
  }
}
