.dropdown-menu {
  width: 200px;
  position: absolute;
  top: 69px;
  list-style: none;
  text-align: start;
  z-index: 1000;
}

.dropdown-menu li {
  background: #020d41;
  cursor: pointer;
}

.dropdown-menu li:hover {
  background: #1888ff;
}

.dropdown-menu.clicked {
  display: none;
}

.dropdown-list {
  display: block;
  font-family: Museo-700;
  width: 100%;
  height: 100%;
  text-decoration: none;
  color: #ffffff;
  padding: 12px 16px;
  text-shadow: none;
}

.dropdown-menu li:nth-child(1) .dropdown-list {
  margin-top: 22.5px;
}

@media screen and (max-width: 943px) {
  .dropdown-menu {
    top: 40px;
    list-style: none;
    text-align: start;
  }
}
