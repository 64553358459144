.addressWrap {
  width: 300px;
  margin: 42px auto 30px;
  text-align: center;
}

.addressContent {
  margin-top: 44px;
  width: 100%;
  /* overflow: hidden; */
}

.message,
.call {
  overflow-x: hidden;
}

.addressContent i {
  color: #ffa200;
  font-size: 32px;
}

.addressContent h4 {
  font-family: Museo-700;
  margin: 8px;
  font-size: 24px;
  color: #05145f;
}

.addressContent p {
  font-family: Museo-300;
  font-size: 18px;
  line-height: 26px;
}
/*---------------- meet icon combined------------------*/

.addressContent .meetIcon {
  position: relative;
  margin-top: 52px;
}

.addressContent .meetIcon i:nth-child(1) {
  color: #fdb539;
}

.addressContent .meetIcon i:nth-child(2) {
  font-size: 24px;
  position: absolute;
  right: 132px;
  color: #ffa200;
  top: -12px;
}

/*---------------- call icon-----------------*/

.call i {
  transform: rotate(-35deg);
}

/*------------------------ tab version------------------ */

@media screen and (min-width: 768px) {
  .addressWrap {
    width: 700px;
    margin: 70px auto 30px;
    display: flex;
    justify-content: center;
    /* align-items: center; */
  }

  .addressContent {
    margin-top: 0px;
    width: 100%;
    /* margin-left: 10px;
    margin-right: 10px; */
  }

  .addressContent i {
    font-size: 40px;
  }

  .addressContent h4 {
    margin: 16px;
    font-size: 28px;
  }

  .addressContent p {
    font-size: 20px;
    line-height: 30px;
  }

  /*---------------- meet icon combined------------------*/

  .meet {
    margin-left: 18px;
  }

  .addressContent .meetIcon {
    position: relative;
    margin-top: 0px;
  }

  .addressContent .meetIcon i:nth-child(2) {
    font-size: 28px;
    position: absolute;
    right: 89px;
    top: -17px;
  }
}

/*-------------------------Desktop version------------------ */

@media screen and (min-width: 1024px) {
  .addressWrap {
    width: 1000px;
    margin: 110px auto 70px;
  }

  .addressContent i {
    font-size: 46px;
  }

  .addressContent h4 {
    margin: 22px;
    font-size: 32px;
  }

  .addressContent p {
    font-size: 20px;
    line-height: 32px;
  }

  /*---------------- meet icon combined------------------*/

  .meet {
    margin-left: 0px;
  }

  .addressContent .meetIcon i:nth-child(2) {
    font-size: 30px;
    position: absolute;
    right: 145px;
    top: -16px;
  }
}

@media screen and (min-width: 1200px) {
  .addressWrap {
    width: 1180px;
  }

  .addressContent .meetIcon i:nth-child(2) {
    position: absolute;
    right: 176px;
    top: -16px;
  }
}
