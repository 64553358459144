.formHeading {
  width: 92%;
  margin: 36px auto 30px;
  text-align: center;
}

.formHeading .topBorder {
  background: #757575;
  height: 1.5px;
  width: 98%;
  margin: auto;
}

.formHeading h4 {
  font-family: Museo-300;
  font-size: 20px;
  letter-spacing: 0.4px;
  width: 200px;
  margin: 22px auto;
  background: rgba(0, 201, 167, 0.1);
  color: rgb(0, 201, 167);
  border: 1px solid transparent;
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem;
}

.formHeading h2 {
  font-family: Museo-700;
  font-size: 28px;
  letter-spacing: 1px;
  margin: 16px auto;
  color: rgb(0, 123, 255);
}

.formHeading h2 span {
  /* display: block; */
  color: #000000;
  margin-top: 2px;
}

.formHeading p {
  font-family: Museo-300;
  font-size: 18px;
  line-height: 24px;
}

/* -------------------Tab Version----------------- */

@media screen and (min-width: 768px) {
  .formHeading {
    width: 100%;
    margin: 40px auto 30px;
  }

  .formHeading .topBorder {
    width: 95%;
  }

  .formHeading h4 {
    font-size: 22px;
    letter-spacing: 0.6px;
    width: 280px;
    margin: 30px auto 22px;
    padding: 0.5rem 0.75rem;
  }

  .formHeading h2 {
    font-size: 32px;
    letter-spacing: 1px;
    margin: 16px auto;
  }

  .formHeading h2 span {
    display: inline-block;
    margin-top: 0px;
  }

  .formHeading p {
    font-size: 20px;
  }
}

/* -------------------Desktop Version----------------- */

@media screen and (min-width: 1024px) {
  .formHeading h4 {
    font-size: 24px;
    width: 300px;
  }

  .formHeading h2 {
    font-size: 34px;
  }

  .formHeading p {
    font-size: 22px;
  }
}
